<template>
  <div class="service-card">
    <div v-if="obj.ind_key">
      <div style="font-size: 15px">
        <div v-if="!obj.ixo_ind_key">{{ obj.ixo_title }}</div>
        <b style="color: orange">{{ obj.primary ? ` ` + i18n['service-card'].tcPrimary : `` }}</b>
      </div>
      <span>
        <b>
          <a href="#" @click.prevent="handleProfileClick"> {{ obj.ind_first_name }} {{ obj.ind_last_name }} </a>
        </b>
      </span>
      <span>{{ contactType }}</span>
    </div>
    <div v-if="obj.ixo_ind_key">
      <span>{{ obj.ixo_title }}</span>
      <span>
        <b><a href="#" @click.prevent="handleProfileClick">{{ obj.name }}</a></b>
        <b style="color: orange">{{ obj.primary_ind_word === 'Yes' ? ` ` + i18n['service-card'].tcPrimary : `` }}</b>
      </span>
      <span v-if="!obj.ind_key">{{ contactType }}</span>
    </div>
    <div v-if="obj.nte_note">
      <span> {{ obj.name }}</span>
      <span><b>{{ obj.contact_type }}</b></span>
      <span>{{ obj.nte_note }}</span>
    </div>
    <div v-if="obj.weekday">
      <span>{{ weekdayType }}, {{ obj.service_time }}</span>
      <span>{{ worshipType }}</span>
      <span>{{ attireType }}</span>
    </div>
    <div v-if="obj.address_type">
      <span>
        <b>{{ addressType }} {{ i18n['service-card'].tcAddress }}</b>
        <b style="color: orange">{{ obj.primary_address_flag === 1 ? ` ` + i18n['service-card'].tcPrimary : `` }}</b>
      </span>
      <span>{{ obj.address_line1 }}</span>
      <span v-if="obj.address_line2">{{ obj.address_line2 }}</span>
      <span v-if="obj.address_line3">{{ obj.address_line3 }}</span>
      <span>{{ obj.city }} {{ obj.state }} {{ obj.postal_code }}</span>
      <span>{{ obj.country }}</span>
    </div>
    <div v-if="obj.phn_key">
      <span>
        <b>{{ phoneType }} {{ phoneOrFax }}</b>
        <b style="color: orange">{{ obj.primary_phone_flag === 1 ? ` ` + i18n['service-card'].tcPrimary : `` }}</b>
      </span>
      <span>{{ obj.number_masked }}</span>
      <span v-if="obj.extension">ext. {{ obj.extension }}</span>
    </div>
    <!-- Store Cart Item -->
    <div v-if="obj.shc_prd_key">
      <span>{{ obj.vprd_product_name }}</span>
      <span>{{ obj.label_item }} {{ obj.vprd_prd_code }}</span>
      <span>{{ obj.label_quanity }} {{ obj.shc_quantity }}</span>
      <span>{{ obj.label_price }} ${{ obj.sum_line_amount.toFixed(2) }}</span>
    </div>
    <!-- /Store Cart Item -->
    <div v-if="obj.eml_key">
      <span>
        <b>{{ emailType }}</b>
        <b style="color: orange">{{ obj.primary_email_flag === 1 ? ` ` + i18n['service-card'].tcPrimary : `` }}</b>
      </span>
      <span>{{ obj.email_address }}</span>
    </div>
    <div class="service-action-bar">
      <div @click="handleEditClick" v-if="okToEdit || iCanSeeAny(secure_edit_keys, ignore.edit)">
        <ServiceEdit />
      </div>
      <div @click="handleDeleteClick"
        v-if="cardCount > (isCandidate ? 0 : 1) && (okToDelete || iCanSeeAny(secure_delete_keys, ignore.delete))">
        <ServiceDelete />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex'
import ServiceDelete from '@/assets/svgs/service-card-delete.vue'
import ServiceEdit from '@/assets/svgs/service-card-edit.vue'

export default {
  props: {
    obj: Object,
    cardCount: { default: 2 },
    okToEdit: { default: false },
    okToDelete: { default: false },
    isCandidate: { default: false },
    secure_edit_keys: {},
    secure_delete_keys: {},
    i18n: {
      type: Object,
      default: () => {
        return {
          'service-card': {
            tcAddress: 'Address',
            tcCancel: 'Cancel',
            tcEmail: 'Email',
            tcExt: '.ext',
            tcFax: 'Fax',
            tcOk: 'Ok',
            tcPhone: 'Phone',
            tcPrimary: 'Primary',
            tcThis: 'This',
            tcTypeAddress: 'address',
            tcTypeContact: 'contact',
            tcTypeEmail: 'email',
            tcTypeItem: 'item',
            tcTypePhone: 'phone',
            tcTypeRep: 'rep',
            tcTypeService: 'service',
            tcWillBeDeleted: 'will be deleted. Is that Ok?',
          },
        }
      },
    },
    ignore: {
      type: Object,
      default: () => {
        return {
          edit: null,
          delete: null,
        }
      },
    },
  },
  methods: {
    async handleEditClick() {
      this.$emit('edit_requested', this.obj)
    },
    async handleProfileClick() {
      this.$emit('profile_requested', this.obj)
    },
    async handleDeleteClick() {
      this.$swal({
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: this.i18n['service-card'].tcOk,
        cancelButtonText: this.i18n['service-card'].tcCancel,
        cancelButtonColor: '#d33',
        text: this.deleteMessage,
      }).then((result) => {
        if (result.value) {
          this.$emit('delete_approved', this.obj)
        }
      })
    },
    translateItemDescription(desc) {
      // Translates an item description (IE, `phone`), for use in a computed property
      let desc_lookup = [
        { type: 'address', text: this.i18n['service-card'].tcTypeAddress },
        { type: 'contact', text: this.i18n['service-card'].tcTypeContact },
        { type: 'church service', text: this.i18n['service-card'].tcTypeChurchService },
        { type: 'email', text: this.i18n['service-card'].tcTypeEmail },
        { type: 'email address', text: this.i18n['service-card'].tcTypeEmail },
        { type: 'item', text: this.i18n['service-card'].tcTypeItem },
        { type: 'phone', text: this.i18n['service-card'].tcTypePhone },
        { type: 'phone number', text: this.i18n['service-card'].tcTypePhone },
        { type: 'rep', text: this.i18n['service-card'].tcTypeRep },
        { type: 'service', text: this.i18n['service-card'].tcTypeService },
      ]
      let desc_lookup_index = desc_lookup.findIndex((dl) => dl.type === desc)
      return desc_lookup_index > -1 ? desc_lookup[desc_lookup_index].text : desc
    },
  },
  computed: {
    ...mapGetters({
      iCanSeeAny: 'user/iCanSeeAny',
    }),
    addressType() {
      const addressGuid = this.obj.adt_key
      return this.i18n.common['address-types'][addressGuid] || this.obj.address_type
    },
    attireType() {
      const attireGuid = this.obj.attire_key ? this.obj.attire_key : ''
      return this.i18n.common['attire'][attireGuid] || this.attire
    },
    contactType() {
      const contactTypeGuid = this.obj.ixo_irt_key ? this.obj.ixo_irt_key : ''
      return this.i18n.common['individual-relationship-type'][contactTypeGuid] || this.obj.contact_type
    },
    deleteMessage() {
      let itemText = ''
      if (this.obj.hasOwnProperty('item_description')) itemText = this.obj.item_description
      else if (this.obj.hasOwnProperty('virt_trn_txt')) itemText = this.obj.virt_trn_txt

      if (!this.i18n['service-card']) {
        return `This ${itemText} will be deleted. Is that Ok?`
      } else {
        const itemDescription = this.translateItemDescription(itemText.toLowerCase())
        return `${itemDescription} ${this.i18n['service-card'].tcWillBeDeleted}`
      }
    },
    emailType() {
      const emailGuid = this.obj.emt_key.toLowerCase()
      return (
        (this.i18n.common['email-types'][emailGuid] || this.obj.email_type) + ` ${this.i18n['service-card'].tcEmail}`
      )
    },
    phoneType() {
      const phoneGuid = this.obj.pht_key || ''
      let phoneTypeStr = ''
      if (this.obj.IsPhone) {
        phoneTypeStr = this.i18n.common['phone-types'][phoneGuid.toLowerCase()] || this.obj.phone_type
      } else if (this.obj.IsFax) {
        phoneTypeStr = this.i18n.common['fax-types'][phoneGuid.toLowerCase()] || this.obj.phone_type
      } else {
        phoneTypeStr = this.obj.phone_type || 'Unspecified'
      }
      return phoneTypeStr
    },
    phoneOrFax() {
      return this.obj.IsPhone
        ? ` ` + this.i18n['service-card'].tcPhone + ` `
        : ` ` + this.i18n['service-card'].tcFax + ` `
    },
    weekdayType() {
      const weekdayGuid = this.obj.weekday_key ? this.obj.weekday_key.toLowerCase() : ''
      return this.i18n.common['days'][weekdayGuid] || this.weekday
    },
    worshipType() {
      const worshipGuid = this.obj.cst_key ? this.obj.cst_key.toLowerCase() : ''
      return this.i18n.common['church-service-type'][worshipGuid] || this.worship_type
    },
  },
  components: {
    ServiceDelete: ServiceDelete,
    ServiceEdit: ServiceEdit,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';

.service-card {
  padding: 20px;
  background-color: #fff;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  margin-right: 20px;
  margin-bottom: 25px;
  flex: 0 1 auto;
  width: calc(33.333% - 15px);
  position: relative;

  @include breakpoint(sm) {
    flex: 1 1 100%;
    margin-right: 0;
  }

  &:nth-child(3n) {
    margin-right: 0;
  }

  span {
    display: block;
    width: calc(100% - 50px);
    font-size: 15px;
    color: #4c4c4c;
    line-height: 20px;
    font-family: $open-sans;
    margin-bottom: 4px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .service-action-bar {
    position: absolute;
    top: 15px;
    right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    >div {
      margin-right: 10px;

      &:hover {
        opacity: 0.6;
      }

      svg {
        cursor: pointer;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}
</style>
