<template>
  <div class="card-item container" :style="spacing">
    <h2 class="m-0 mr-auto">{{ name }}</h2>
    <span class="arrow-down-icon">
      <img src="../assets/svgs/arrow-down.svg" />
    </span>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'CardItem',
  props: {
    name: { type: String },
    spacing: {
      type: Object,
      default: () => {
        return {
          'margin-bottom': '0px',
        }
      },
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchProfile.scss';
</style>
